import React, { useState } from 'react';
import './Deactivate.css';

function Deactivate() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleDeactivate = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/deactivate', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error || 'an error occurred.');
      }
    } catch (error) {
      console.error('error during deactivation:', error);
      setMessage('an error occurred while processing your request.');
    }
  };

  return (
    <div className="deactivate-container">
      <h1>deactivate account</h1>
      <p>we're sorry to see you go. please enter your email to deactivate your account.</p>
      <form onSubmit={handleDeactivate}>
        <input
          type="email"
          placeholder="your uchicago email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">deactivate account</button>
      </form>
      {message && <p className="response-message">{message}</p>}
    </div>
  );
}

export default Deactivate;