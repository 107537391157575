import React, { useState, useEffect } from 'react';
import './ClearLikes.css';

const ClearLikes = () => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isDeactivated, setIsDeactivated] = useState(false);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const response = await fetch('/api/auth-status', {
          method: 'GET',
          credentials: 'include',
        });

        const data = await response.json();

        if (data.is_authenticated) {
          setIsDeactivated(data.user.is_deactivated);
        }
      } catch (error) {
        console.error('Error fetching user status:', error);
      }
    };

    fetchUserStatus();
  }, []);

  const handleClearLikesSubmit = (e) => {
    e.preventDefault();
    if (isDeactivated) {
      setMessage('Your account is deactivated.');
    } else {
      setConfirmationVisible(true);
    }
  };

  const handleConfirmClearLikes = async () => {
    try {
      const response = await fetch('/api/clear-likes', {
        method: 'POST',
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Likes cleared successfully.');
      } else {
        setMessage(data.error || 'Error clearing likes.');
      }
    } catch (error) {
      console.error('Error clearing likes:', error);
      setMessage('Error clearing likes.');
    }

    setConfirmationVisible(false);
  };

  const handleCancelClearLikes = () => {
    setConfirmationVisible(false);
  };

  return (
    <div className="clear-likes-container">
      <h1>Clear All Likes Sent</h1>
      <p>Are you sure you want to clear all likes you have sent?</p>
      <form onSubmit={handleClearLikesSubmit}>
        <button type="submit" className="clear-likes-button">
          clear likes
        </button>
      </form>

      {message && <p className="response-message">{message}</p>}

      {/* Confirmation Popup */}
      {confirmationVisible && (
        <div className="confirmation-popup">
          <p>Are you sure you want to clear all likes?</p>
          <div className="popup-buttons">
            <button className="clear-likes-button confirm-yes" onClick={handleConfirmClearLikes}>
              Yes
            </button>
            <button className="clear-likes-button confirm-no" onClick={handleCancelClearLikes}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClearLikes;
