import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer({ isAuthenticated }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <footer className="footer">
      <div className="footer-links" style={{ justifyContent: isMobile ? 'space-between' : 'flex-start', width: '100%' }}>
        <Link to="/deactivate" className="footer-link">deactivate</Link>
        <Link to="/unsubscribe" className="footer-link">unsubscribe</Link>
        {isAuthenticated && (
          <Link to="/clear-likes" className="footer-link">clear likes</Link>
        )}
      </div>

      {/* Conditionally render contact information only on desktop when logged out */}
      {!isAuthenticated && !isMobile && (
        <p className="footer-contact">
          inquiries: <a href="mailto:contact@maroonmatch.com" className="footer-link">contact@maroonmatch.com</a>
        </p>
      )}
    </footer>
  );
}

export default Footer;
