import React, { useEffect, useState } from 'react';
import './NotificationBox.css';

const NotificationBox = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="error-box">
      {message}
    </div>
  );
};

export default NotificationBox;
