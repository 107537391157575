import React, { useRef, useEffect, useState } from 'react';
import './Login.css';
import NotificationBox from './NotificationBox';

function Login() {
  const secondSectionRef = useRef(null);
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleLogin = () => {
    console.log("login button clicked. redirecting to backend login...");
    window.location.href = '/api/login';
  };

  const handleScroll = () => {
    if (secondSectionRef.current) {
      secondSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('/api/stats');
        if (response.ok) {
          const data = await response.json();
          setStats(data);
        } else {
          const errorData = await response.json();
          setError(errorData.error || 'Failed to fetch stats.');
        }
      } catch (err) {
        console.error('Error fetching stats:', err);
        setError('An error occurred while fetching stats.');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <main className="login-page">
      {/* NotificationBox for displaying errors */}
      {error && <NotificationBox message={error} />}

      {/* Background Image Section */}
      <div className="login-background">
        <div className="overlay">
          <h1 className="header-text">match with your crush, only @ uchicago</h1>
          <button className="login-button" onClick={handleLogin}>
            log in with uchicago email
          </button>
        </div>
        <div className="scroll-arrow" onClick={handleScroll}>
          &#8595;
        </div>
      </div>

      {/* Information Section */}
      <div className="second-screen" ref={secondSectionRef}>
        <div className="left-side">
          {/* Feature 1 */}
          <div className="feature">
            <p className="feature-title">no swiping.</p>
            <p className="feature-description">
              at maroon match, you send likes to people you already know by typing in their uchicago email.
              your interests remain anonymous until there’s a match.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="feature">
            <p className="feature-title">no choosing pictures to use.</p>
            <p className="feature-description">
              you spend enough time looking good in the real world. no need to worry about selecting the
              perfect photo, just mog irl.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="feature">
            <p className="feature-title">no account signup.</p>
            <p className="feature-description">
              don’t worry about remembering your password. connect using your uchicago credentials. your identity, and likes, are securely encrypted.
            </p>
          </div>
        </div>

        {/* Statistics Section */}
        <div className="right-side">
          {/* New Heading */}
          <h2 className="stats-heading">stats for nerds</h2>

          {/* Statistics Columns */}
          <div className="stats-columns">
            {/* Left Column */}
            <div className="left-column">
              <div className="stat">
                <span className="icon">👤</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.unique_logins.toLocaleString()} unique logins` : '--- unique logins'}
                </h2>
              </div>
              <div className="stat">
                <span className="icon">❤️</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.likes_sent.toLocaleString()} likes sent` : '--- likes sent'}
                </h2>
              </div>
              <div className="stat">
                <span className="icon">📧</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.unique_emails_liked.toLocaleString()} unique emails liked` : '--- unique emails liked'}
                </h2>
              </div>
              <div className="stat">
                <span className="icon">🔗</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.matches_made.toLocaleString()} matches made` : '--- matches made'}
                </h2>
              </div>
            </div>

            {/* Right Column */}
            <div className="right-column">
              <div className="stat">
                <span className="icon">🟢</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.users_logged_in_now.toLocaleString()} users logged in now` : '--- users logged in now'}
                </h2>
              </div>
              <div className="stat">
                <span className="icon">📈</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.conversion_rate} conversion rate` : '--- conversion rate'}
                </h2>
              </div>
              <div className="stat">
                <span className="icon">🚫</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.accounts_deactivated.toLocaleString()} accounts deactivated` : '--- accounts deactivated'}
                </h2>
              </div>
              <div className="stat">
                <span className="icon">✉️</span>
                <h2>
                  {loading ? 'Loading...' : stats ? `${stats.accounts_unsubscribed.toLocaleString()} accounts unsubscribed` : '--- accounts unsubscribed'}
                </h2>
              </div>
            </div>
          </div>

          {loading && <p className="stats-loading">Loading statistics...</p>}
        </div>
      </div>
    </main>
  );
}

export default Login;
