import React from 'react';
import './About.css';

function About() {
  const handleLogin = () => {
    console.log("Login button clicked. Redirecting to backend login...");
    window.location.href = '/api/login';
  };

  return (
    <div className="about-page">
      
      {/* Highest level: What is Maroon Match? */}
      <div className="about-level highest-level">
        <div className="about-text">
          <h1>what is maroon match?</h1>
          <p>
            maroon match is a secure, anonymous crush matching platform specifically for uchicago students, built by a uchicago student. 
            social anxiety and the difficulty of expressing feelings are real barriers for many students.
            with maroon match, you can submit someone's email address, and if they submit your email in return, 
            you are both notified. otherwise, nothing happens, and no one is the wiser.
          </p>
        </div>
        <div className="about-image">
          <img src="/images/cats.png" alt="two cute cats cuddling" />
          <p className="caption">you and who?</p>
        </div>
      </div>

      {/* Middle level: Okay, Why? and How? Side by side */}
      <div className="about-level middle-level">
        <div className="about-text">
          <h1>okay, why?</h1>
          <p>
            it’s hard enough to talk to your crush normally. uchicago students are not normal. we’ve all had that crush 
            but didn’t know how to approach it, and went back and forth in our heads over whether we were just being delusional 
            or they were totally into us, etc. maroon match lets you bypass the guessing game.
          </p>
        </div>
        <div className="about-text">
          <h1>how?</h1>
          <p>
            maroon match uses a robust tech stack to ensure security and privacy. the backend is built on flask with postgresql 
            and sqlalchemy orm. the frontend is built with react.js and material-ui. google oauth and duo 2fa keep the platform 
            exclusive to current students. finally, sensitive data is encrypted using the cryptography library in python. this 
            ensures that even if data is somehow intercepted, it is unreadable without the correct key, which lives in google 
            cloud’s key management servers.
          </p>
        </div>
      </div>

      {/* Lowest level: Ok, I'm Sold with a button */}
      <div className="about-level lowest-level">
        <div className="about-text centered-content">
          <h1>ok, i'm sold. how can i get started?</h1>
          <button className="cta-button" onClick={handleLogin}>i got you</button>
        </div>
        <div className="about-image">
          <img src="/images/computer.png" alt="a comically large computer" />
          <p className="caption">real image of maroon match dev environment</p>
        </div>
      </div>
      
    </div>
  );
}

export default About;
