import React, { useState } from 'react';
import './FAQ.css';

function FAQ() {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "what is maroon match?",
      answer: "maroon match is a platform for uchicago students to match with people they already know. it lets you submit someone’s uchicago email, and if they submit yours in return, you’re both notified of the match. otherwise the submission remains private."
    },
    {
        question: "how is maroon match different from dating apps?",
        answer: "i personally find dating apps gross because of how artificial everything feels, especially profile creation and how weird people can be in dms. maroon match was designed as the opposite of that; it should be about intentional connections with people you already know."
    },
    {
      question: "who is maroon match for?",
      answer: "maroon match is for uchicago students who are interested in relationships with people in their community. it’s meant as an alternative to the anxiety provoking, transactional, overall icky dating apps we are forced to use."
    },
    {
      question: "how do i log in to maroon match?",
      answer: "sign in with your uchicago email and complete duo 2fa."
    },
    {
      question: "is maroon match anonymous?",
      answer: "yes. when you like someone it remains private until there’s a match."
    },
    {
      question: "how does the matching system work?",
      answer: "when a user submits a like, the server checks if that user has also liked them. if they have, a match is created and both are notified. otherwise nothing happens."
    },
    {
      question: "what kind of notifications will i get?",
      answer: "users receive notifications for likes, matches, when their like cooldown expires, and unsubscription/deactivation requests."
    },
    {
      question: "how often can i like someone?",
      answer: "you can like someone every 9 days. this keeps it meaningful. you’re not on tinder."
    },
    {
      question: "how do i know when my like refreshes?",
      answer: "there’s a timer in the like screen. alternatively, if you want to receive an email each time your like refreshes, email contact@maroonmatch.com with the subject line 'cooldown notifications'."
    },
    {
      question: "can i unlike someone?",
      answer: "yes! log in, then go to the clear likes page, located in the footer. you can clear all of the likes you have sent from there. note that this can’t be undone."
    },
    {
      question: "what if i accidentally enter the wrong email?",
      answer: "if you've made a small typo, don't worry; it's unlikely to affect anything, as the submission remains private unless there's a match. however, if you really need to correct it, you can always clear all of your sent likes by logging in and navigating to the clear likes page in the footer."
    },
    {
      question: "can i delete my account or unsubscribe from emails?",
      answer: "yes, click the button in the footer and you’ll receive a confirmation link to complete the process."
    },
    {
      question: "what happens if i delete my account or unsubscribe from emails?",
      answer: "if you delete your account, all your data, including likes and matches are permanently removed and you will no longer receive emails or have access to the platform. if you unsubscribe from emails, you won’t receive emails, but your account will remain active and you can still use the platform."
    },
    {
      question: "how can i re-subscribe to emails or re-activate my account?",
      answer: "send an email to contact@maroonmatch.com"
    },
    {
      question: "is maroon match free?",
      answer: "for now breh"
    },
    {
      question: "what if i have a question that isn’t covered here?",
      answer: "reach out to contact@maroonmatch.com"
    }
  ];

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleQuestion(index)}>
              {item.question}
            </div>
            <div className={`faq-answer ${openQuestion === index ? 'open' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
