import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

function UnsubscribeConfirm() {
  const location = useLocation();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    console.log('UnsubscribeConfirm component mounted');
    console.log('Received token:', token);

    if (!token) {
      setStatus('error');
      setMessage('Invalid or missing token.');
      return;
    }

    const confirmUnsubscribe = async () => {
      try {
        const response = await fetch('/api/unsubscribe/confirm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
          credentials: 'include',
        });

        const data = await response.json();

        if (response.ok) {
          setStatus('success');
          setMessage(data.message || 'You have been unsubscribed successfully.');
        } else {
          setStatus('error');
          setMessage(data.message || 'An error occurred during unsubscription.');
        }
      } catch (error) {
        console.error('Error confirming unsubscription:', error);
        setStatus('error');
        setMessage('An unexpected error occurred. Please try again later.');
      }
    };

    confirmUnsubscribe();

    window.history.replaceState(null, null, window.location.pathname);
  }, [location.search]);

  return (
    <div className="confirmation-page">
      <h1>Unsubscribe Confirmation</h1>
      {status === 'loading' && <p>Processing your unsubscription...</p>}
      {status === 'success' && (
        <>
          <p className="success-message">{message}</p>
          <p>You will no longer receive emails from us.</p>
          <Link to="/">Go to Homepage</Link>
        </>
      )}
      {status === 'error' && (
        <>
          <p className="error-message">{message}</p>
          <p>Something went wrong, please try again later.</p>
          <Link to="/">Return to Home</Link>
        </>
      )}
    </div>
  );
}

export default UnsubscribeConfirm;
