import React, { useState } from 'react';
import './Unsubscribe.css';

function Unsubscribe() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleUnsubscribe = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/unsubscribe', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error during unsubscription:', error);
      setMessage('An error occurred while processing your request.');
    }
  };

  return (
    <div className="unsubscribe-container">
      <h1>unsubscribe from emails</h1>
      <p>if you no longer wish to receive emails from maroon match, please enter your email below.</p>
      <form onSubmit={handleUnsubscribe}>
        <input
          type="email"
          placeholder="your uchicago email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="unsubscribe-button">unsubscribe</button> {/* Add unique class */}
      </form>
      {message && <p className="response-message">{message}</p>}
    </div>
  );
}

export default Unsubscribe;
