import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-page">
      <div className="content">
        <h1>contact</h1>
        <p>
          for questions, concerns, feedback, bug reports, suggestions, emotional support,
          outfit advice, or if you just want to say hi, please reach out at:
        </p>
        <p><strong>contact@maroonmatch.com</strong></p>
      </div>
    </div>
  );
}

export default Contact;
