import React from 'react';

function AccountDeactivated() {
  return (
    <div className="account-deactivated-container">
      <h1>Your Account is Deactivated</h1>
      <p>We're sorry to see you go. Your account has been deactivated and you can no longer use Maroon Match.</p>
      <p>If you believe this is a mistake or wish to reactivate your account, please contact support at <a href="mailto:contact@maroonmatch.com">contact@maroonmatch.com</a>.</p>
    </div>
  );
}

export default AccountDeactivated;
