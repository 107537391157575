import React from 'react';
import './FirstTimeLoginPopup.css';

const FirstTimeLoginPopup = ({ onClose }) => {
  return (
    <div className="confirmation-popup">
      <h2>welcome to maroon match!</h2>
      <p className="popup-text">
        here’s how it works:
      </p>
      <p className="popup-text">
        type in any uchicago email to send that person a like. if they’ve already liked you, you’ll both be notified. 
        otherwise, they’ll get an email that someone liked them, but they won’t know it was you. if they like you back, 
        you’ll both get a match notification and email. :)
      </p>
      <p className="popup-text">
        you can only send a like every 9 days. this isn’t tinder.
      </p>
      <p className="popup-text">
        if you’re curious about how anything works, check out the faq or send me an email at contact@maroonmatch.com.
      </p>
      <p className="popup-text">
        enjoy!
      </p>
      <div className="popup-buttons">
        <button className="like-button confirm-yes" onClick={onClose}>get started</button>
      </div>
    </div>
  );
};

export default FirstTimeLoginPopup;
