import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

function DeactivateConfirm() {
  const location = useLocation();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    console.log('DeactivateConfirm component mounted');
    console.log('Received token:', token);

    if (!token) {
      setStatus('error');
      setMessage('Invalid or missing token.');
      return;
    }

    const confirmDeactivation = async () => {
      try {
        const response = await fetch('/api/deactivate/confirm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
          credentials: 'include',
        });

        const data = await response.json();

        if (response.ok) {
          setStatus('success');
          setMessage(data.message || 'Your account has been deactivated.');
        } else {
          setStatus('error');
          setMessage(data.message || 'An error occurred during deactivation.');
        }
      } catch (error) {
        console.error('Error confirming deactivation:', error);
        setStatus('error');
        setMessage('An unexpected error occurred. Please try again later.');
      }
    };

    confirmDeactivation();

    window.history.replaceState(null, null, window.location.pathname);
  }, [location.search]);

  return (
    <div className="confirmation-page">
      <h1>Account Deactivation Confirmation</h1>
      {status === 'loading' && <p>Processing your deactivation...</p>}
      {status === 'success' && (
        <>
          <p className="success-message">{message}</p>
          <p>You will no longer have access to your account.</p>
          <Link to="/">Return to Home</Link>
        </>
      )}
      {status === 'error' && (
        <>
          <p className="error-message">{message}</p>
          <p>Something went wrong, please try again later.</p>
          <Link to="/">Return to Home</Link>
        </>
      )}
    </div>
  );
}

export default DeactivateConfirm;
